import { t } from 'i18next';
import { AddPlus2 } from '../../assets/icons';
import {
  Button,
  ButtonSize,
  ButtonType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui';
import { ChargerSharedDetailEditor } from './ChargerSharedDetailEditor';
import { ChargerSpecificDetailEditor } from './ChargerSpecificDetailEditor';

interface IProps {
  disabled?: boolean;
}

const AddCharger = ({ disabled = false }: IProps) => {
  const { showModal } = useGlobalModalContext();

  const handleAddChargerClick = () => {
    showModal(MODAL_TYPES.WIZARD_MODAL, {
      stepTitles: [
        t('chargers_add_step_1_title'),
        t('chargers_add_step_2_title'),
      ],
      stepComponents: [
        <ChargerSharedDetailEditor key='shared_details_editor' />,
        <ChargerSpecificDetailEditor key='specific_details_editor' />,
      ],
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <Button
      icon={AddPlus2}
      onClick={handleAddChargerClick}
      label={t('chargers_add_label')}
      size={ButtonSize.SMALL}
      type={ButtonType.TERTIARY}
      dataTestId='addChargerButton'
      iconAlt={t('icon_alt_add_charger')}
      disabled={disabled}
    />
  );
};

export default AddCharger;
