import { NumberLiteralType } from 'typescript';
import { Location } from '.';
import { AccessRule } from './access/accessRule.interface';
import { DeploymentInfo, RawChargerStatus } from './chargers.interface';

export interface ChargerPort {
  id: string;
  physicalLocation: string;
  status: string;
  connectorTypes: string[];
  maxWattage: number;
  rawChargerStatus: RawChargerStatus;
  stateOfCharge?: number;
}
export interface Charger {
  id: string;
  completeTime?: Date;
  createTime?: Date;
  startTime?: Date;
  stopTime?: Date;
  billedTotalAmount?: number;
  consumedEnergyJoules?: number;
  status: string;
  transactionId?: string;
  name?: string;
  type?: string;
  usageNotes?: string;
  access?: string;
  location?: Location;
  model?: string;
  autoStartEnabled?: boolean;
  maintenanceFlag: boolean;
  currentPrice?: {
    currency: string;
    flatRate: number;
    ratePerEffectiveHour: number;
    ratePerHourPluggedIn: number;
    ratePerKilowattHour: number;
  };
  free: boolean;
  maxPowerKW: number;
  imageUrl?: string;
  installInfo?: string;
  installationVoltage?: NumberLiteralType;
  port: {
    id: string;
    physicalLocation: string;
    charger: {
      id: string;
      model: string;
      name: string;
      type: string;
      location: Location;
    };
  };
  ports: ChargerPort[];
  directions?: string;
  parkingSpot?: string;
  associatedBuildingUnit?: string;
  internalNote?: string;
  description?: string;
  isFree?: boolean;
  lastUsed?: number;
  autoStartConfig?: any;
  serialNumber?: string;
  priceModelDisplayName?: string;
  accessRules?: AccessRule[];
  ocppId: string;
  hiddenOnMap: boolean;
  priceModelId: string;
  isPowerManaged: boolean;
  deploymentInfo: DeploymentInfo;
  chargerModelId: string;
  vendor: string;
  powerManagementCompatible: boolean;
  ownerId?: string; // CompanyId for multi-view
  maxAmperage?: number;
  minAmp?: number;
  zeroAmpSupport?: boolean;
  supportZeroAmpsOverride?: boolean;
  maxAmpsOverride?: number;
  supportZeroAmps?: boolean;
  paymentTerminalSerialNumber?: string;
  licenseKey?: string;
}

export interface ChargerStatus {
  status: string;
  count: number;
}

export interface ChargerSummary {
  title: string;
  value: string;
}

export interface Session {
  id: string;
  port: {
    id: string;
    physicalLocation: string;
    status: string;
    connectorTypes: string[];
    maxWattage?: number;
    charger?: any;
  };
  completeTime: Date;
  chargerId?: string;
  portId: string;
  createTime?: Date;
  startTime?: Date;
  stopTime?: Date;
  status: string;
  startedVia: string;
  accumulatedTotals?: {
    pluggedInTimeSeconds: number;
    effectiveChargingTimeSeconds: number;
    consumedEnergyJoules: number;
    cost: number;
    currency: string;
  };
  transactionId?: string;
  pluggedInTimeSeconds: number;
  effectiveChargingTimeSeconds: number;
  consumedEnergyKwh: number;
  billedTotalAmount: number;
  billedCurrency: string;
  vehicleId?: string;
  vehicleName?: string;
  vin?: string;
  rfids?: string[];
  vehicleIdActive?: boolean;
  receiptId?: string;
  rawChargerStatus?: RawChargerStatus;
  paymentTerminalSerialNumber?: string;
  paymentTerminalSessionId?: string;
}

export interface SessionObj {
  totalCount: number;
  entities: Session[];
}
export interface RecentSessions {
  chargers: Charger[];
  chargerStatus: ChargerStatus[];
  chargerSummary: ChargerSummary[];
  filter: any;
  stats: any[];
}

export enum SESSION_TYPES {
  PAYMENT_TERMINAL = 'payment_terminal',
  DRIVER_APP = 'driver_app',
  CHARGER_AUTO_START = 'charger_auto_start',
  WEB_APP = 'web_app',
  IOS_APP = 'ios_app',
  ANDROID_APP = 'android_app',
  DASHBOARD = 'dashboard',
  ROAM = 'roam',
}
