import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType, Skeleton } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

interface IProps {
  name: string;
  collection: string;
  currency?: string;
  className?: string;
  isLoading?: boolean;
  info?: string;
}

const ReportItem = ({
  name,
  collection,
  currency,
  className = 'bg-grey0',
  isLoading = false,
  info,
}: IProps) => {
  return isLoading ? (
    <Skeleton
      className='rounded-2xl block w-full bg-grey2'
      height='h-[120px]'
      loadingColor='white'
    />
  ) : (
    <div className={`block h-[120px] w-full ${className}  p-4 rounded-2xl`}>
      <div className='flex flex-row'>
        <Label
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY5}
          text={name}
          className='mr-1'
        />
        {info && (
          <Tooltip>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_20x20} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={info}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <div className='flex justify-between mt-7'>
        <Label type={LabelType.H2} color={ColorType.BLACK} text={collection} />
        <Label
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY5}
          text={currency}
          className='flex items-end'
        />
      </div>
    </div>
  );
};

export default ReportItem;
