/* eslint-disable react/jsx-wrap-multilines */
import { monthsToYears } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargerEV } from '../../assets/icons';
import { useCharger } from '../../hooks/useChargers';
import { useCompany } from '../../hooks/useCompany';
import { License } from '../../stores/types';
import { convertToDate, formatDate } from '../../utils/Date.Util';
import { ColorType, Pill } from '../_ui';
import { Label, LabelType } from '../_ui/Label.component';
import LicenseDetailLabelPair from './LicenseDetailLabelPair.component';
import LicenseStatus from './LicenseStatus.component';
import {
  LICENSE_STATUS,
  checkIfElaOrEssentials,
  daysDifferenceText,
  getReadableLicense,
} from './utils';

interface LicenseDetailsProps {
  license: License;
}

const LicenseDetails = React.memo(({ license }: LicenseDetailsProps) => {
  const { t } = useTranslation();
  const { getChargerNameById } = useCharger();
  const { companies, companyId } = useCompany();

  const portKeys = useMemo(() => {
    if (!license?.ports || license.ports === 1) return null;

    return Array.from({ length: license.ports }, (_, index) => (
      <Label
        key={index}
        text={`Port ${index + 1} key: ${license.licenseKey}.${index + 1}`}
        type={LabelType.BODY4}
        color={ColorType.GREY6}
      />
    ));
  }, [license?.licenseKey, license?.ports]);

  const distributingCompany = useMemo(() => {
    if (!companies) return null;

    const currentCompany = companies.find(
      (company) => company.id === companyId,
    );

    const channelPartnerId = currentCompany?.businessInfo?.channelPartnerId;
    return channelPartnerId
      ? companies.find((company) => company.id === channelPartnerId)
      : null;
  }, [companyId, companies]);

  const licenseType = useMemo(() => {
    return getReadableLicense(license.type);
  }, [license.type]);

  return (
    <div className='flex flex-col pb-5'>
      <LicenseDetailLabelPair
        label={t('license_key')}
        value={
          <div className='flex flex-col gap-y-1'>
            <Label
              text={license.licenseKey}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            {portKeys}
          </div>
        }
        height={license?.ports && license.ports > 1 ? 'h-[84px]' : 'h-10'}
        alignItems='items-start'
      />
      <LicenseDetailLabelPair label={t('order_id')} value={license.orderId} />
      <LicenseDetailLabelPair
        label={t('distributing_company')}
        value={distributingCompany?.name}
      />
      <LicenseDetailLabelPair
        label={t('order_note')}
        value={license?.orderNote}
      />
      <LicenseDetailLabelPair
        label={t('license_status')}
        value={<LicenseStatus status={license.status} isLoading={false} />}
      />
      <LicenseDetailLabelPair
        label={t('assigned_charger')}
        value={
          license.assignedCharger && (
            <Pill
              iconLeft={ChargerEV}
              label={getChargerNameById(license.assignedCharger)}
            />
          )
        }
      />
      <LicenseDetailLabelPair
        label={t('license_type')}
        value={t(licenseType)}
      />
      <LicenseDetailLabelPair
        label={t('port_count_for_license')}
        value={license?.ports}
      />
      <LicenseDetailLabelPair
        label={t('license_includes_4g')}
        value={license.includes4G ? t('yes') : t('no')}
      />
      <LicenseDetailLabelPair
        label={t('duration')}
        value={
          checkIfElaOrEssentials(license.type)
            ? t('duration_not_available')
            : t('license_grid_duration', {
                number: monthsToYears(license.duration),
              })
        }
      />
      <LicenseDetailLabelPair
        label={t('expires')}
        alignItems='items-start'
        height='h-13'
        value={
          checkIfElaOrEssentials(license.type) ? (
            t('license_does_not_expire')
          ) : (
            <div className='flex flex-col gap-y-1'>
              {license?.expirationDate && (
                <>
                  <Label
                    text={formatDate(
                      convertToDate(license.expirationDate),
                      'MMM d, yyyy',
                    )}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                  />
                  <Label
                    text={
                      license.status?.toLowerCase() === LICENSE_STATUS.EXPIRED
                        ? t('expired_license_days', {
                            noOfYearsDays: daysDifferenceText(
                              true,
                              license?.expirationDate,
                              t,
                            ),
                          })
                        : t('license_remaining_days', {
                            noOfYearsDays: daysDifferenceText(
                              false,
                              license?.expirationDate,
                              t,
                            ),
                          })
                    }
                    type={LabelType.BODY4}
                    color={ColorType.GREY6}
                  />
                </>
              )}
            </div>
          )
        }
      />
    </div>
  );
});

LicenseDetails.displayName = 'LicenseDetails';
export default LicenseDetails;
