/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

import { Invite } from '../../../stores/types';

/* eslint-disable prefer-regex-literals */
const regex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/,
);

const accentedCharacterRegex = /[À-ž]/;

export const hasAccentedCharacter = (value: any) => {
  return accentedCharacterRegex.test(value);
};

export const validateEmail = (value: any) => {
  return regex.test(value) && !hasAccentedCharacter(value);
};

export const validateOcppId = (value: any) => {
  const ocppRegex = /^([a-zA-Z0-9-*_=+.|@])+$/;
  return ocppRegex.test(value);
};

export const isValidURL = (url: string): boolean => {
  const urlPattern =
    /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/[\w\d-]+)*\/?(\?[^\s]*)?(#[^\s]*)?$/i;
  return urlPattern.test(url);
};

const regexCurrency = new RegExp(/^[0-9]\d{0,2}(?:,?\d{3})*(?:\.\d{0,2})?$/);
export const validateCurrency = (value: any, greatThanZero: boolean = true) => {
  if (greatThanZero && Number(value) <= 0) {
    return false;
  }
  return regexCurrency.test(value);
};

const regexEmailDomain = new RegExp(
  /^$|^([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/,
);

export const validateDomainName = (value: any) => {
  if (value) {
    return regexEmailDomain.test(value);
  }
};

export const validateDisplayId = (value: any) => {
  const reg = /^[a-zA-Z0-9*-_|@]+$/;
  return reg.test(value);
};

export const sortInvitedCompanyByDate = (invitesList: Invite[]) => {
  const sortedStats = invitesList ? [...invitesList] : [];

  return sortedStats.sort((a: any, b: any) =>
    new Date(a.dateRequested) < new Date(b.dateRequested) ? 1 : -1,
  );
};

export const MODAL_LAYER_INDEX = 1000;
