import { useCompany } from '../../hooks/useCompany';
import { LicenseProvider } from '../../hooks/useLicense';
import { useFetchLicenseListQuery } from '../../services/license.api';
import { SCOPE_TYPE } from '../../services/utils';
import LicensesGrid from './LicensesGrid.component';
import LicensesWrapper from './LicensesWrapper.component';
import NoLicense from './NoLicense.component';
import LicenseFilters from './filters/LicenseFilters.component';

const Licences = () => {
  const { userScope } = useCompany();
  const { licensesTotalCount, isLoading } = useFetchLicenseListQuery(
    // In case userScope is undefined in order to avoid the error sending the restricted scope "COMPANY"
    { scope: userScope || SCOPE_TYPE.COMPANY },
    {
      selectFromResult: (endPoint) => ({
        isLoading:
          (endPoint.isLoading || endPoint.isFetching) && !endPoint?.data,
        licensesTotalCount: endPoint.data?.totalCount || 0,
      }),
    },
  );

  return !licensesTotalCount ? (
    <LicensesWrapper>
      <NoLicense isLoading={isLoading} />
    </LicensesWrapper>
  ) : (
    <LicenseProvider>
      <LicensesWrapper>
        <LicenseFilters />
        <LicensesGrid />
      </LicensesWrapper>
    </LicenseProvider>
  );
};

export default Licences;
