import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { payterContext } from '../../../hooks/usePayter';
import { ColorType, Label, LabelType } from '../../_ui';
import ViewPayout from './ViewPayout.component';

interface IProps {
  date: Date;
  onClickTerminalState?: () => void;
}

export enum PAYMENT_METHODS {
  STRIPE = 'STRIPE',
  PAYTER = 'PAYTER',
}

const ReportStats = ({ date, onClickTerminalState }: IProps) => {
  const { t } = useTranslation();

  const payterConsumer = useContext(payterContext);
  const { showTerminal, isPayterConnected, company } = payterConsumer;
  return showTerminal && isPayterConnected ? (
    <div className='flex items-center justify-between mt-4 gap-4'>
      <ViewPayout
        className='bg-positive0'
        date={date}
        paymentLabel={t('payter_total_revenue')}
        defaultCompanyCurrency={company?.currency}
      />
      <ViewPayout
        date={date}
        paymentLabel={t('collected_via_driver_app')}
        paymentMethods={PAYMENT_METHODS.STRIPE}
        defaultCompanyCurrency={company?.currency}
      />
      <ViewPayout
        date={date}
        info={t('payment_terminal_info_text')}
        paymentLabel={t('collected_via_payment_terminal')}
        paymentMethods={PAYMENT_METHODS.PAYTER}
        defaultCompanyCurrency={company?.currency}
      />
    </div>
  ) : (
    <div className='mt-4'>
      <div className='mb-2'>
        <Label
          text={
            isPayterConnected
              ? t('total_terminals_revenue')
              : t('payter_total_revenue')
          }
          type={isPayterConnected ? LabelType.LABEL_M_MEDIUM : LabelType.BODY2}
          color={ColorType.GREY5}
          className='inline'
          dataTestId='revenue-collected-directly'
        />
      </div>
      <ViewPayout
        date={date}
        isShowTotal
        defaultCompanyCurrency={company?.currency}
      />
      {!showTerminal && isPayterConnected && (
        <div className='mt-2'>
          <Label
            text={t('show_payment_info')}
            type={LabelType.BODY2}
            color={ColorType.BRAND2}
            className='inline'
            onClick={onClickTerminalState}
          />
        </div>
      )}
    </div>
  );
};

export default ReportStats;
