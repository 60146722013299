import { FloatingDelayGroup } from '@floating-ui/react';
import { useState } from 'react';
import { CheckMark, Copy } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { Icon } from '../Icon.component';
import { Label, LabelType } from '../Label.component';
import { ColorType } from '../shared/Colors.g';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip.component';

const CopyTooltip = ({ text }: any) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <FloatingDelayGroup delay={500}>
      <Tooltip>
        <TooltipTrigger>
          <Label
            className='text-left whitespace-nowrap w-[120px] truncate'
            text={text}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </TooltipTrigger>
        <TooltipContent>
          <span
            className='flex flex-row gap-1 justify-center items-center text-sm text-white font-medium'
            style={{ fontFamily: 'Inter' }}
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(text);
              setIsCopied(true);
              // 5 second to reset copy state
              setTimeout(() => setIsCopied(false), 5000);
            }}
          >
            <span>{text}</span>
            {!isCopied && (
              <Icon
                src={Copy}
                color={ColorType.WHITE}
                size={IconSize.SIZE_16x16}
                alt=''
              />
            )}
            {isCopied && (
              <Icon
                src={CheckMark}
                color={ColorType.POSITIVE0}
                size={IconSize.SIZE_16x16}
                alt=''
              />
            )}
          </span>
        </TooltipContent>
      </Tooltip>
    </FloatingDelayGroup>
  );
};

export default CopyTooltip;
