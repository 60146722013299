export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
  JMD = 'JMD',
  BBD = 'BBD',
}

interface IdleFeeRange {
  maxAmount: number;
  startAmount: number;
  endAmount: number;
  tooLowAmount: number;
}

export const CURRENCY_IDLE_FEE_RANGES: Record<Currency, IdleFeeRange> = {
  [Currency.CAD]: {
    maxAmount: 3.0,
    startAmount: 0.05,
    endAmount: 1.0,
    tooLowAmount: 0.04,
  },
  [Currency.USD]: {
    maxAmount: 3.0,
    startAmount: 0.05,
    endAmount: 1.0,
    tooLowAmount: 0.04,
  },
  [Currency.JMD]: {
    maxAmount: 470,
    startAmount: 7.85,
    endAmount: 155,
    tooLowAmount: 6.3,
  },
  [Currency.BBD]: {
    maxAmount: 6,
    startAmount: 0.1,
    endAmount: 2.0,
    tooLowAmount: 0.08,
  },
};
