import { useTranslation } from 'react-i18next';
import { NoLicenses } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType, Skeleton } from '../_ui';

interface IPROPS {
  isLoading: boolean;
  isDataExists?: boolean;
}
const NoLicense = ({ isLoading, isDataExists = false }: IPROPS) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-1 items-center justify-center py-4'>
      {isLoading ? (
        <Skeleton width='w-12' height='h-12' />
      ) : (
        <Icon src={NoLicenses} className='grey5' size={IconSize.SIZE_48x48} />
      )}
      <Label
        isLoading={isLoading}
        className='mt-1'
        skeletonWidth='w-[180px]'
        text={t(
          isDataExists ? 'no_licenses_page_title' : 'no_licenses_added_title',
        )}
        type={LabelType.H4}
        color={ColorType.GREY5}
      />
      <Label
        isLoading={isLoading}
        skeletonWidth='w-[360px]'
        className='mt-1'
        text={t(
          isDataExists ? 'no_licenses_found_desc' : 'no_licenses_page_desc',
        )}
        type={LabelType.BODY2}
        color={ColorType.GREY5}
      />
    </div>
  );
};

export default NoLicense;
