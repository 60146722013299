import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NUMBER } from '../../constant/Number.constant';
import { useCompany } from '../../hooks/useCompany';
import { useFetchChargersQuery } from '../../services/charger.api';
import { useFetchLocationsQuery } from '../../services/location.api';
import { Company } from '../../stores/types';
import { MODAL_TYPES, useGlobalModalContext } from '../_ui';
import { SubmitLoading } from '../_ui/Skeleton/SubmitLoading.component';
import { LocationEditor } from '../company/LocationEditor.component';
import NoChargerAndLocation from './NoChargerAndLocation.component';

interface IPROPS {
  children: React.ReactNode;
}

const ChargerWrapper = ({ children }: IPROPS) => {
  const { t } = useTranslation();
  const { companies, companyId, userScope } = useCompany();
  const { showModal } = useGlobalModalContext();

  const selectedCompany = useMemo(() => {
    return companies?.find((company: Company) => company.id === companyId);
  }, [companies, companyId]);

  const { noOfChargers, isChargerLoading } = useFetchChargersQuery(
    { scope: userScope },
    {
      selectFromResult: (endPoint) => ({
        isChargerLoading: endPoint.isLoading,
        noOfChargers: endPoint.data?.totalCount || NUMBER.ZERO,
      }),
    },
  );
  const { noOfLocations, isLocationLoading } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        isLocationLoading: endPoint.isLoading,
        noOfLocations: endPoint.data?.totalCount || NUMBER.ZERO,
      }),
    },
  );

  const handleAddLocationClick = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('company_add_location'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <LocationEditor company={selectedCompany} />,
      shouldCloseOnOverlayClick: false,
    });
  };

  if (isChargerLoading || isLocationLoading) {
    return (
      <div className='flex items-center inset-2/4 fixed'>
        <SubmitLoading isTransparentBg />
      </div>
    );
  }
  return (
    <div>
      {noOfLocations && noOfChargers ? (
        children
      ) : (
        <NoChargerAndLocation
          hasLocations={!!noOfLocations}
          handleAddLocationClick={handleAddLocationClick}
        />
      )}
    </div>
  );
};

export default ChargerWrapper;
