import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLicense } from '../../../hooks/useLicense';
import { Dropdown, DropdownType, LabelType } from '../../_ui';
import { FILTER_TYPES } from '../utils';

interface Iprops {
  typeDropDownList: any;
}
const TypeDropdown = ({ typeDropDownList }: Iprops) => {
  const { t } = useTranslation();
  const { licenseFilterquery, handleSelectFilter } = useLicense();

  const selectedFilter = useMemo(() => {
    return licenseFilterquery.type?.length
      ? licenseFilterquery.type.map((item: string) => {
          return { type: 'PORT', id: item };
        })
      : [];
  }, [licenseFilterquery]);

  const handleSelectType = (items: any) => {
    const existingSelectedFilter = [...selectedFilter];
    const selectedChargerFilters: any[] = [];

    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        const filterObj = { type: 'PORT', id: child.id };
        if (child.selected) {
          if (!_.some(selectedFilter, filterObj)) {
            selectedChargerFilters.push(filterObj);
          }
        } else if (_.some(selectedFilter, filterObj)) {
          _.remove(existingSelectedFilter, { id: child.id });
        }
      });
    });

    handleSelectFilter(
      FILTER_TYPES.TYPE,
      [...existingSelectedFilter, ...selectedChargerFilters].map(
        (ele) => ele.id,
      ),
    );
  };

  return (
    <Dropdown
      placeholder={t('license_type')}
      placeholderLabelType={LabelType.SESSION_DROPDOWN_HEADER}
      type={DropdownType.CHECKBOX_TREE}
      headerWidth='auto'
      items={typeDropDownList}
      onItemClick={handleSelectType}
      translationOn
      showFooter
    />
  );
};

export default TypeDropdown;
