import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLicense } from '../../../hooks/useLicense';
import { Dropdown, DropdownType, LabelType } from '../../_ui';
import { FILTER_TYPES } from '../utils';

interface IPROPS {
  chargersFilterDropdownList: any;
}
const ChargerDropdown = ({ chargersFilterDropdownList }: IPROPS) => {
  const { t } = useTranslation();
  const { licenseFilterquery, handleSelectFilter } = useLicense();

  const selectedFilter = useMemo(() => {
    return licenseFilterquery.chargerId?.length
      ? licenseFilterquery.chargerId.map((item: string) => {
          return { type: 'CHARGER', id: item };
        })
      : [];
  }, [licenseFilterquery]);

  const handleChargersFilterChange = (items: any) => {
    const existingSelectedFilter = [...selectedFilter];
    const selectedChargerFilters: any[] = [];

    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        const filterObj = { type: 'CHARGER', id: child.id };
        if (child.selected) {
          if (!_.some(selectedFilter, filterObj)) {
            selectedChargerFilters.push(filterObj);
          }
        } else if (_.some(selectedFilter, filterObj)) {
          _.remove(existingSelectedFilter, { id: child.id });
        }
      });
    });

    handleSelectFilter(
      FILTER_TYPES.CHARGER_ID,
      [...existingSelectedFilter, ...selectedChargerFilters].map(
        (ele) => ele.id,
      ),
    );
  };

  return (
    <Dropdown
      placeholder={t('charger')}
      placeholderLabelType={LabelType.SESSION_DROPDOWN_HEADER}
      headerWidth='auto'
      type={DropdownType.CHECKBOX_TREE}
      items={chargersFilterDropdownList}
      onItemClick={handleChargersFilterChange}
      showFooter
      dataTestId='license-select-charger'
    />
  );
};

export default ChargerDropdown;
