import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useLazyFetchChargersQuery } from '../services/charger.api';
import { Charger } from '../stores/types';
import { useCompany } from './useCompany';

type ContextType = {
  chargers: Charger[];
  getChargerNameById: (id: string) => string;
};

const initialState: ContextType = {
  chargers: [],
  getChargerNameById: (id: string) => id,
};

export const chargerContext = createContext(initialState);

export const useCharger = () => {
  return useContext(chargerContext);
};

interface IPROPS {
  children: React.ReactNode;
}

export const ChargerProvider = ({ children }: IPROPS) => {
  const { userScope, companyId } = useCompany();

  const [triggerFetchCharger, { data }] = useLazyFetchChargersQuery();

  useEffect(() => {
    if (companyId) {
      triggerFetchCharger({
        scope: userScope,
      });
    }
  }, [companyId, userScope]);

  const chargers = useMemo(() => data?.entities || [], [data]);

  const getChargerNameById = useCallback(
    (id: string) => {
      const chargerByid = chargers?.find(
        (charger: Charger) => charger.id === id,
      );
      return chargerByid?.name || '';
    },
    [chargers],
  );

  const values = useMemo(() => {
    return {
      chargers: chargers || [],
      getChargerNameById,
    };
  }, [chargers, getChargerNameById]);

  return (
    <chargerContext.Provider value={values}>{children}</chargerContext.Provider>
  );
};
