import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../hooks/useCompany';
import { useAddLicenseMutation } from '../../services/license.api';
import { LicensePostRequest } from '../../stores/types/licenses.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  Dropdown,
  DropdownType,
  FormInput,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../_ui';
import { Form } from '../_ui/Form.component';
import { InputType, ValidationType } from '../_ui/Input.component';
import {
  DURATION_TYPES,
  durationTypeValues,
  getInternalLicenseType,
  getReadableLicense,
  LICENSE_TYPES,
  PORT_COUNT,
} from './utils';

export interface Props {
  license?: LicensePostRequest;
}

export const LicenseAdd = memo(() => {
  const { t } = useTranslation();
  const { userScope, companyId } = useCompany();
  const { hideModal } = useGlobalModalContext();
  const [triggerAddLicense, addLicenseQueryResult] = useAddLicenseMutation();
  const [_license, setLicense] = useState({
    companyId: companyId,
    includes4G: false,
    numberOfLicenses: 0,
  } as LicensePostRequest);

  const licenseTypeDropdown = useMemo(() => {
    return Object.values(LICENSE_TYPES).map((type) => ({
      id: type,
      label: t(`${type}`),
      selected: getReadableLicense(_license.type) === type,
    }));
  }, [_license]);

  const disablePorts = useMemo(() => {
    return _license.type === LICENSE_TYPES.HOME;
  }, [_license]);

  const portsPerLicenseDropdown = useMemo(() => {
    const availablePorts = disablePorts
      ? [PORT_COUNT.ONE]
      : [PORT_COUNT.ONE, PORT_COUNT.TWO];
    return Object.values(availablePorts).map((ports) => ({
      id: ports,
      label: `${ports}`,
      selected: _license.ports === ports,
    }));
  }, [_license, disablePorts]);

  const disableDuration = useMemo(() => {
    return (
      _license.type?.toLocaleLowerCase().startsWith('ela') ||
      _license.type?.toLocaleLowerCase().startsWith('home') ||
      _license.type?.toLocaleLowerCase().startsWith('essentials')
    );
  }, [_license]);

  const licenseDurationDropdown = useMemo(() => {
    return Object.values(DURATION_TYPES).map((duration) => ({
      id: duration,
      label: t(`${duration}`),
      selected:
        _license.duration ===
        durationTypeValues[
          duration?.toUpperCase() as keyof typeof durationTypeValues
        ],
      disabled: disableDuration,
    }));
  }, [_license, disableDuration]);

  const handleInputChange = (key: string, value: any) => {
    // Make duration undefined if type is home/ela/essentials
    if (
      key === 'type' &&
      [
        LICENSE_TYPES.HOME,
        LICENSE_TYPES.ESSENTIALS,
        LICENSE_TYPES.ELA_L2,
        LICENSE_TYPES.ELA_DC,
      ].includes(value)
    ) {
      setLicense({
        ..._license,
        [key]: value,
        duration: undefined,
      });
    } else {
      setLicense({
        ..._license,
        [key]: value,
      });
    }
  };

  const renderDuration = () => {
    return (
      <div className='flex flex-row'>
        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex flex-row gap-2 items-baseline'>
            <Label
              text={t('duration')}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
          </div>
          <Dropdown
            placeholder={t('duration')}
            placeholderLabelType={LabelType.BODY3_G4}
            headerWidth={490}
            items={licenseDurationDropdown}
            type={DropdownType.SELECT}
            contentDivWidth={490}
            labelType={LabelType.BODY3}
            labelTypeHighlighted={LabelType.BODY3}
            chevdownIconHighlightColor={ColorType.GREY6}
            headerHighLightClassName='bg-grey1 border-grey3 rounded'
            onItemClick={(item: any) =>
              handleInputChange(
                'duration',
                durationTypeValues[
                  item.id?.toUpperCase() as keyof typeof durationTypeValues
                ],
              )
            }
            disabled={disableDuration}
          />
        </div>
      </div>
    );
  };

  const transformLicenseData = (license: LicensePostRequest) => {
    const updatedLicense = {
      ...license,
      type: getInternalLicenseType(license.type, license.ports),
    };
    return updatedLicense;
  };

  const handleSave = () => {
    triggerAddLicense({
      license: transformLicenseData(_license),
      userScope: userScope || 'company',
    });
  };

  const onSaveValidation = () => {
    if (
      !_license?.type ||
      !_license?.ports ||
      (!disableDuration && !_license?.duration) ||
      _license.numberOfLicenses <= 0 ||
      _license.numberOfLicenses >= 100
    ) {
      return false;
    }
    return true;
  };

  return (
    <Form
      onSubmit={handleSave}
      queryResult={addLicenseQueryResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <div className='flex flex-col justify-between h-full'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('license_type_placeholder')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
              </div>
              <Dropdown
                placeholder={t('license_type_placeholder')}
                placeholderLabelType={LabelType.BODY3_G4}
                headerWidth={240}
                items={licenseTypeDropdown}
                type={DropdownType.SELECT}
                contentDivWidth={240}
                labelType={LabelType.BODY3}
                labelTypeHighlighted={LabelType.BODY3}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded'
                onItemClick={(item: any) => handleInputChange('type', item.id)}
              />
            </div>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('ports_per_license')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
              </div>
              <Dropdown
                placeholder={t('ports_per_license')}
                placeholderLabelType={LabelType.BODY3_G4}
                headerWidth={240}
                items={portsPerLicenseDropdown}
                type={DropdownType.SELECT}
                contentDivWidth={240}
                labelType={LabelType.BODY3}
                labelTypeHighlighted={LabelType.BODY3}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded'
                onItemClick={(item: any) => handleInputChange('ports', item.id)}
              />
            </div>
          </div>
          {renderDuration()}
          <div className='flex flex-row'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <Label
                  text={t('license_includes_chargelab_4g')}
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                />
              </div>
              <CheckBox
                label={t('yes')}
                selected={_license.includes4G}
                onChange={(value: boolean) =>
                  handleInputChange('includes4G', value)
                }
              />
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <FormInput
                  inputId='number_of_license'
                  label={t('number_of_license')}
                  placeholder={t('number_of_license_placeholder')}
                  inputType={InputType.NUMBER}
                  validationType={ValidationType.POSITIVE_NUMBER_LESS_THAN_100}
                  errorLabel={t('number_of_license_validation_message')}
                  props={{ style: { width: '65px' } }}
                  onChange={(event: any) =>
                    handleInputChange(
                      'numberOfLicenses',
                      Number(event.target.value),
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='flex-1 flex flex-col gap-2'>
              <div className='flex flex-row gap-2 items-baseline'>
                <FormInput
                  inputId='order_note'
                  defaultValue={_license?.orderNote}
                  label={t('order_note')}
                  secondLabel={`(${t('chargers_optional')})`}
                  secondLabelType={LabelType.BODY2}
                  secondLabelColor={ColorType.GREY6}
                  placeholder={t('order_note')}
                  inputType={InputType.TEXT}
                  props={{ style: { width: '490px' } }}
                  onChange={(event: any) =>
                    handleInputChange('orderNote', event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className='mb-[20px] w-[490px]'>
            <Button
              label={t('save')}
              size={ButtonSize.SMALL_FULL}
              type={ButtonType.PRIMARY}
              disabled={!onSaveValidation()}
              isSumbit
              dataTestId='licenseSave'
            />
          </div>
        </div>
      </div>
    </Form>
  );
});

LicenseAdd.displayName = 'LicenseAdd';
