import { useTranslation } from 'react-i18next';
import { AddPlus2 } from '../../assets/icons';
import {
  Button,
  ButtonSize,
  ButtonType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui';
import { LicenseAdd } from './LicenseAdd.component';

const GenerateLicenses = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const handleAddLicenseClick = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('generate_licenses_modal_title'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <LicenseAdd />,
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <Button
      icon={AddPlus2}
      onClick={handleAddLicenseClick}
      label={t('generate_licenses')}
      size={ButtonSize.SMALL}
      type={ButtonType.TERTIARY}
      iconAlt={t('generate_licenses')}
    />
  );
};

export default GenerateLicenses;
