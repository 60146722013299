import React, { ReactNode } from 'react';
import { ColorType, Label, LabelType } from '../_ui';

interface LicenseDetailLabelPairProps {
  label: string;
  value: string | number | ReactNode;
  height?: string;
  alignItems?: string;
  valueClassName?: string;
}

const LicenseDetailLabelPair = React.memo(
  ({
    label,
    value,
    height = 'h-10',
    alignItems = 'items-center',
    valueClassName = '',
  }: LicenseDetailLabelPairProps) => {
    return (
      <div className={`flex flex-row ${height} ${alignItems} py-2.5`}>
        <Label
          text={label}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        {typeof value === 'string' || typeof value === 'number' ? (
          <Label
            text={value}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            className={`pl-3 ${valueClassName}`}
          />
        ) : (
          <div className={`pl-3 ${valueClassName}`}>{value}</div>
        )}
      </div>
    );
  },
);

LicenseDetailLabelPair.displayName = 'LicenseDetailLabelPair';

export default LicenseDetailLabelPair;
