import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddPlus2, LocationPindrop, NoChargers } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '../_ui';
import AddCharger from './AddCharger.component';

interface IPROPS {
  hasLocations: boolean;
  handleAddLocationClick: any;
}
const NoChargerAndLocation = ({
  hasLocations,
  handleAddLocationClick,
}: IPROPS) => {
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  return (
    <Card className='py-4'>
      <div className='divide-y divide-grey2'>
        <div className='p-0'>
          <div className='flex flex-row items-center justify-between pb-4'>
            <div className='flex'>
              <Label
                text={t('chargers')}
                type={LabelType.H4}
                color={ColorType.BLACK}
              />
            </div>
            <div className='flex'>
              <AddCharger disabled={!hasLocations} />
            </div>
          </div>
          <div className='flex flex-col gap-1 items-center justify-center py-4'>
            <Icon
              src={hasLocations ? NoChargers : LocationPindrop}
              className='grey5'
              size={IconSize.SIZE_48x48}
            />
            <Label
              className='mt-1'
              skeletonWidth='w-[180px]'
              text={t(
                hasLocations ? 'no_chargers_added' : 'no_location_added_title',
              )}
              type={LabelType.H4}
              color={ColorType.GREY5}
            />
            <Label
              skeletonWidth='w-[360px]'
              className='mt-1'
              text={t(
                hasLocations
                  ? 'no_charger_added_desc'
                  : 'no_location_added_desc',
              )}
              type={LabelType.BODY2}
              color={ColorType.GREY5}
            />
            {!hasLocations && (
              <Button
                icon={AddPlus2}
                className='mt-4'
                onClick={handleAddLocationClick}
                label={t('company_add_location')}
                iconColor={theme.colorPalettes.primary}
                size={ButtonSize.SMALL}
                type={ButtonType.SECONDARY}
                dataTestId='addNewLocation'
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NoChargerAndLocation;
