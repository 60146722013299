import { useTranslation } from 'react-i18next';
import { ColorType, Label, LabelType, Skeleton } from '../_ui';
import { LICENSE_STATUS } from './utils';

interface IProps {
  status: string;
  isLoading: boolean;
}
const LicenseStatus = ({ status, isLoading }: IProps) => {
  const { t } = useTranslation();
  const label = status?.toLowerCase();
  let primaryColor = '';
  let labelColor = ColorType.GREY6;
  let bgColor = 'white';
  switch (label) {
    case LICENSE_STATUS.ACTIVE:
    case LICENSE_STATUS.ASSIGNED:
      primaryColor = ColorType.POSITIVE;
      labelColor = ColorType.POSITIVE;
      ColorType.GREY6;
      break;
    case LICENSE_STATUS.UNASSIGNED:
      primaryColor = ColorType.ACCENT1;
      labelColor = ColorType.ACCENT1;
      break;
    case LICENSE_STATUS.EXPIRED:
      primaryColor = ColorType.WARNING2;
      labelColor = ColorType.GREY6;
      bgColor = 'warning_light';
      break;
    default:
      primaryColor = ColorType.POSITIVE;
  }

  return isLoading ? (
    <Skeleton innerClassName='rounded-3xl' height='h-6' width='w-14' isPill />
  ) : (
    <div
      className={`px-2 py-0.5 gap-1 border-2 border-${primaryColor} bg-${bgColor} rounded-3xl box-border flex flex-row items-center h-6 whitespace-nowrap w-max overflow-hidden`}
    >
      <Label text={t(label)} type={LabelType.BODY3} color={labelColor} />
    </div>
  );
};

export default LicenseStatus;
