/* eslint-disable react/display-name */
import { Placement } from '@floating-ui/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPlus2 } from '../../assets/icons';
import { USER_ROLE, useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { CompanyEditor } from '../company/CompanyEditor.component';
import { Button, ButtonSize, ButtonType } from './Button.component';
import Divider from './Divider.component';
import { LabelType } from './Label.component';
import { MODAL_TYPES, useGlobalModalContext } from './modal';
import { Select, SelectItem } from './select/Select.component';
import { ColorType } from './shared/Colors.g';

type CompanySelectProp = {
  buttonProps: { [key: string]: any }; // button props
  placement?: Placement;
  contentWidth?: number;
  contentHeight?: number;
};

export const CompanySelect = memo(
  ({
    buttonProps,
    placement = 'bottom-start',
    contentWidth = 280,
    contentHeight = 480,
  }: CompanySelectProp) => {
    const auth = useAuth();
    const { companyId, companies, switchCompany, isMultiViewActive } =
      useCompany();
    const { t } = useTranslation();
    const { showModal } = useGlobalModalContext();

    const companyDropdownList = useMemo(() => {
      const res = [];
      res.push({ id: 'ALL', name: t('all_companies') });
      if (companies && companies.length > 0) {
        res.push(...companies);
      }
      return res;
    }, [companies, t]);

    const onCompanySelected = async (index: number) => {
      const selectedCompany = companyDropdownList[index];
      if (selectedCompany && companyId !== selectedCompany.id) {
        switchCompany(selectedCompany.id);
      }
    };

    const defaultIndex = useMemo(() => {
      return companyDropdownList?.findIndex(
        (company) => company.id === companyId,
      );
    }, [companyId, companyDropdownList]);

    const handleAddCompanyClick = () => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('company_add'),
        width: '540px',
        height: 'max-content',
        onRenderBody: () => <CompanyEditor />,
        shouldCloseOnOverlayClick: false,
      });
    };

    const renderAddCompany = () => {
      if (auth.role === USER_ROLE.SUPPORT) {
        return (
          <div className='divide-y divide-grey3 sticky bottom-0 bg-white'>
            <Button
              icon={AddPlus2}
              label={t('company_add')}
              className='mt-2 pl-2 pr-[12px] text-left '
              type={ButtonType.OUTLINE}
              labelType={LabelType.LABEL_M}
              labelColor={ColorType.GREY6}
              size={ButtonSize.SMALL_FULL}
              onClick={handleAddCompanyClick}
            />
          </div>
        );
      }
      return null;
    };

    const items = useMemo(() => {
      const res: any[] = [];
      companyDropdownList.forEach((company) => {
        res.push(
          <SelectItem
            key={company.id}
            label={company.name}
            labelColor={ColorType.BLACK}
            labelType={LabelType.BODY3}
          />,
        );
        if (company.id.toUpperCase() === 'ALL') {
          res.push(<Divider key={`${company.id}divider`} />);
        }
      });
      return res;
    }, [companyDropdownList]);

    return (
      <Select
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        placement={placement}
        defaultIndex={defaultIndex}
        onItemSelected={onCompanySelected}
        buttonProps={buttonProps}
        renderFooter={renderAddCompany}
        usePortal
      >
        {items}
      </Select>
    );
  },
);
