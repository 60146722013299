/* eslint-disable react/destructuring-assignment */
import { Amplify, Auth, Hub } from 'aws-amplify';
import cryptoRandomString from 'crypto-random-string';
import { usePostHog } from 'posthog-js/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from '../components/_ui/modal/utils';
import { Api } from '../services/base.api';
import { httpRawGet } from '../services/http/http.service';
import { i18n } from '../services/translation/i18n';
import { getWhiteLabellingCompanyName } from '../stores/selectors/env.selector';
import { pushUserProperties } from '../utils/GoogleAnalytics';

export enum USER_ROLE {
  SUPPORT = 'SUPPORT',
  NORMAL = 'NORMAL',
}

export interface UserAttributes {
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    country?: string;
    isoCode?: string;
    postalCode?: string;
    province?: string;
  };
  'custom:legacy_support_da'?: string;
  'custom:stable_user_id'?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  profile?: {
    lastActiveCompanyId?: string;
    sitehostDashboardLanguage?: string;
  };
  sub?: string;
}

export interface User {
  id?: string;
  username?: string;
  attributes: UserAttributes;
}

type ContextType = {
  signIn: Function;
  signOut: Function;
  signUp: Function;
  resendCode: Function;
  verifyCode: Function;
  setToken: Function;
  cleanError: Function;
  setCognitoUser: Function;
  handleUserChange: Function;
  setLoading: Function;
  updateUserLanguage: Function;
  updateCompanyId: Function;
  cognitoUser: any;
  user: any;
  role: USER_ROLE | undefined;
  token: string;
  scope: string;
  error: string;
  loading: boolean;
};

const initalState: ContextType = {
  signIn: () => {},
  signOut: () => {},
  signUp: () => {},
  resendCode: () => {},
  verifyCode: () => {},
  setToken: () => {},
  cleanError: () => {},
  setCognitoUser: () => {},
  handleUserChange: () => {},
  updateUserLanguage: () => {},
  updateCompanyId: () => {},
  setLoading: () => {},
  cognitoUser: {},
  loading: false,
  user: {
    attributes: {
      sub: '',
      email_verified: true,
      phone_number_verified: true,
      email: '',
      family_name: '',
      given_name: '',
      phone_number: '',
      profile: {
        sitehostDashboardLanguage: 'en-US',
        lastActiveCompanyId: '',
        acceptedSiteHostTerms: '',
      },
    },
    username: '',
  },
  role: USER_ROLE.NORMAL,
  token: '',
  scope: 'company',
  error: '',
};

const transformUserInfo = (newUserinfo: any) => {
  let profile;
  if (newUserinfo.attributes?.profile) {
    profile = JSON.parse(newUserinfo.attributes.profile);
  }
  newUserinfo.attributes.profile = {
    // Check on 'en' is needed as before BF-1855 was fixed,
    // webapp used en instead of en-US and that caused conflicts
    // as both sitehostdashboard and webapp shares cognito information
    sitehostDashboardLanguage: profile?.sitehostDashboardLanguage
      ? profile?.sitehostDashboardLanguage === 'en'
        ? 'en-US'
        : profile?.sitehostDashboardLanguage
      : 'en-US',
    lastActiveCompanyId: profile?.lastActiveCompanyId || '',
    acceptedSiteHostTerms: profile?.acceptedSiteHostTerms || '1.0',
  };

  return newUserinfo;
};

const useProvideAuth = () => {
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState('');
  const [scope, setScope] = useState('company');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const handleUserChange = (newUser: User) => {
    if (!newUser) {
      return;
    }
    if (newUser.attributes['custom:stable_user_id']) {
      posthog.identify(newUser.attributes['custom:stable_user_id']);
      // Add some posthog user properties for identify
    }

    setUser(newUser);
  };

  const role = useMemo(() => {
    return user?.attributes['custom:legacy_support_da'] === 'ALLOW'
      ? USER_ROLE.SUPPORT
      : USER_ROLE.NORMAL;
  }, [user]);
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);

  const signIn = (loginUsername: string, sendLoginCodeToEmail: boolean) => {
    if (!loginUsername) {
      setError('Please enter your email or phone number.');
      return;
    }

    Auth.signIn(loginUsername.trim())
      .then((data) => {
        Auth.sendCustomChallengeAnswer(data, loginUsername.trim(), {
          isEmail: `${sendLoginCodeToEmail}`,
        }).then((newCognitoUser) => {
          setCognitoUser(newCognitoUser);
        });
      })
      .catch((e: any) => setError(e.message));
  };

  const signUp = async (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    siteHostTermsOfServiceVersion: string,
  ) => {
    const userProfile = {
      acceptedSiteHostTerms: siteHostTermsOfServiceVersion,
    };
    try {
      const attributes: any = {};
      attributes.given_name = firstName;
      attributes.family_name = lastName;
      attributes.email = email.trim();
      if (phoneNumber) attributes.phone_number = phoneNumber;
      attributes.profile = JSON.stringify(userProfile);
      await Auth.signUp({
        username: cryptoRandomString({ length: 16, type: 'alphanumeric' }),
        password: cryptoRandomString({ length: 120, type: 'ascii-printable' }), // May have a chance to not comply with the Cognito password rules
        attributes: attributes,
      });
    } catch (e: any) {
      setError(e.message);
    }
  };

  const updateUserLanguage = async (lang: string) => {
    const newProfile = {
      ...user.attributes.profile,
      sitehostDashboardLanguage: lang,
    };
    Auth.updateUserAttributes(cognitoUser, {
      profile: JSON.stringify(newProfile),
    });
    user.attributes.profile = newProfile;
    handleUserChange(user);
    i18n.changeLanguage(lang);
    if (document) {
      document.documentElement.lang = lang;
    }
    setDefaultLocale(lang);
  };

  const updateCompanyId = async (
    companyId: string,
    cleanCache: boolean = true,
  ) => {
    const newProfile = {
      ...user.attributes.profile,
      lastActiveCompanyId: companyId,
    };
    user.attributes.profile = newProfile;
    // cleanCache is for deeplinking, when users manually refresh the page, we don't need to clean cache again
    if (cleanCache) {
      dispatch(Api.util.resetApiState());
    }
    handleUserChange(user);
    await Auth.updateUserAttributes(cognitoUser, {
      profile: JSON.stringify(newProfile),
    });
  };

  // Currently our system doesn't send text to non-NorthAmerican numbers
  // Hence tif username is a phone number with a country code other than '1', send login code to email.
  const resendCode = () => {
    Auth.signIn(cognitoUser.username)
      .then((data) => {
        Auth.sendCustomChallengeAnswer(data, cognitoUser.username, {
          isEmail: `${
            validateEmail(cognitoUser.username) ||
            !cognitoUser.username.startsWith('+1')
          }`,
        }).then((newCognitoUser) => {
          setCognitoUser(newCognitoUser);
          setLoading(false);
        });
      })
      .catch((e: any) => {
        setError(e.message);
        setLoading(false);
      });
  };

  const verifyCode = async (code: string) => {
    Auth.sendCustomChallengeAnswer(cognitoUser, code).catch((e) => {
      setError(`verifyCode failed: ${e.message}, ${e}`);
      setLoading(false);
    });
  };

  const signOut = () => {
    try {
      Auth.signOut().catch((e) => {});
      dispatch(Api.util.resetApiState());
      // Retain the language value from localstorage even after signout (BF-1375)
      const lang = localStorage.getItem('language') || 'en-US';

      // Retain hideModal- key/value pairs even after signout (ON-473)
      const matchingKeys = [];
      for (let i = 0; i < localStorage.length; i += 1) {
        const key = localStorage.key(i);
        if (key?.includes('hideModal-')) {
          matchingKeys.push({ key, value: localStorage.getItem(key) || false });
        }
      }
      localStorage.clear();
      localStorage.setItem('language', lang);
      matchingKeys.forEach((item) => {
        localStorage.setItem(item.key, item.value?.toString());
      });
      setCognitoUser(null);
      posthog.reset();
      setToken('');
    } catch (e: any) {
      setError(`error signing out: ${e.message}`);
    }
  };

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setCognitoUser(data.payload.data);
          Auth.currentUserInfo()
            .then((signInUserInfo) => {
              const transformedUser = transformUserInfo(signInUserInfo);
              // Set the user selected language as soon as user sign in.
              // It overrides the user system's language
              i18n.changeLanguage(
                transformedUser.attributes.profile.sitehostDashboardLanguage,
              );
              handleUserChange(transformedUser);
              // Set acceptedSiteHostTerms to 1.0 for users that are already registered in our system
              // before PM-1738 was implemented
              Auth.updateUserAttributes(data.payload.data, {
                profile: JSON.stringify({
                  ...transformedUser.attributes.profile,
                  acceptedSiteHostTerms:
                    transformedUser.attributes.profile?.acceptedSiteHostTerms,
                }),
              });
              try {
                pushUserProperties(transformedUser.username, {
                  company: whiteLabelCompanyName,
                });
              } catch (e: any) {
                console.warn(e.message);
              }
            })
            .catch((e) => {});
          break;
        case 'signUp':
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          break;
        case 'configured':
          break;
        default:
          break;
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const cleanError = () => setError('');
  // Return the user object and auth methods
  return {
    user,
    cognitoUser,
    role,
    token,
    scope,
    error,
    loading,
    signIn,
    signUp,
    signOut,
    setToken,
    setLoading,
    resendCode,
    verifyCode,
    cleanError,
    setCognitoUser,
    handleUserChange,
    updateUserLanguage,
    updateCompanyId,
  };
};

const authContext = createContext(initalState);

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthProvider = (props: any) => {
  const { children } = props;
  const [isConfigInited, setIsConfigInited] = useState(false);
  const auth = useProvideAuth();
  const setupCognito = async () => {
    const dep = await httpRawGet('/deployment/cognito').catch((e) => e);
    Amplify.configure({
      Auth: {
        region: dep?.region,
        userPoolId: dep?.userPoolId,
        userPoolWebClientId: dep?.clientId,
        mandatorySignIn: false,
        authenticationFlowType: 'CUSTOM_AUTH',
      },
    });
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      auth.setCognitoUser(cognitoUser);

      const userInfo = await Auth.currentUserInfo();
      auth.handleUserChange(transformUserInfo(userInfo));
    } catch (e: any) {
      console.log('get auth user error:', e.message);
    }
    setIsConfigInited(true);
  };
  useEffect(() => {
    setupCognito();
  }, []);
  if (!isConfigInited) {
    return null;
  }
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
