import { useTranslation } from 'react-i18next';
import { useLicense } from '../../../hooks/useLicense';
import { ColorType, Label, LabelType } from '../../_ui';

const ClearAllButton = () => {
  const { t } = useTranslation();
  const { handleClearAllClick } = useLicense();
  return (
    <div className='flex flex-row gap-4'>
      <button type='button' className='text-left' onClick={handleClearAllClick}>
        <Label
          type={LabelType.DROPDOWN_ITEM_SELECTED}
          color={ColorType.DENIM}
          text={t('session_clear_all')}
        />
      </button>
    </div>
  );
};

export default ClearAllButton;
