import { ReactNode } from 'react';
import { ColorType, Label, LabelType } from '../../_ui';

interface IProps {
  label: string;
  value?: string | number | ReactNode;
  isLoading: boolean;
}
const LicenseLineItem = ({ label, value = '', isLoading }: IProps) => {
  return (
    <div className='flex flex-row py-[10px] min-h-[40px]'>
      <Label
        text={label}
        type={LabelType.LABEL_S}
        color={ColorType.GREY6}
        className='min-w-[148px] max-w-[148px] mr-2.5'
      />
      {typeof value === 'string' || typeof value === 'number' ? (
        <Label
          text={value}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      ) : (
        value
      )}
    </div>
  );
};

export default LicenseLineItem;
