import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import {
  FOUR_G_STATUS,
  LICENSE_STATUS,
  durationTypeValues,
  licenseTypeValues,
} from '../components/Licences/utils';
import { NUMBER } from '../constant/Number.constant';
import { CommaArrayParam } from '../constant/UrlSearchParams.constant';
import { useFetchLicenseListQuery } from '../services/license.api';
import { SCOPE_TYPE } from '../services/utils';
import { License } from '../stores/types';
import { useCompany } from './useCompany';

type ContextType = {
  licensesData: License[];
  isLicenseLoading: boolean;
  licensesDataCount: number;
  licenseFilterquery: any;
  totalSelectedFilters: number;
  handleSelectFilter: (type: string, value: Array<string>) => void;
  handleClearAllClick: () => void;
  handleLoadPage: (page: number) => void;
};

const initialState: ContextType = {
  licensesData: [],
  isLicenseLoading: false,
  licensesDataCount: 0,
  licenseFilterquery: {},
  totalSelectedFilters: 0,
  handleSelectFilter: () => {},
  handleClearAllClick: () => {},
  handleLoadPage: () => {},
};

export const licenseContext = createContext(initialState);

export const useLicense = () => {
  return useContext(licenseContext);
};

interface IPROPS {
  children: React.ReactNode;
}

export const LicenseProvider = ({ children }: IPROPS) => {
  const { userScope } = useCompany();
  const [licenseFilterquery, setLicenseFilterquery] = useQueryParams({
    status: CommaArrayParam,
    duration: CommaArrayParam,
    type: CommaArrayParam,
    includes4G: CommaArrayParam,
    chargerId: CommaArrayParam,
    searchValue: CommaArrayParam,
    page: NumberParam,
  });

  const ROW_PER_PAGE = NUMBER.TWENTY;

  useEffect(() => {
    setLicenseFilterquery({ page: NUMBER.ONE });
  }, [setLicenseFilterquery]);

  const totalSelectedFilters = useMemo(() => {
    const filtersObj = {
      status: licenseFilterquery.status ?? [],
      duration: licenseFilterquery.duration ?? [],
      type: licenseFilterquery.type ?? [],
      includes4G: licenseFilterquery.includes4G ?? [],
      chargerId: licenseFilterquery.chargerId ?? [],
      searchValue: licenseFilterquery.searchValue ?? [],
    };
    return Object.values(filtersObj).reduce(
      (sum, arr) => sum + (arr?.length || 0),
      0,
    );
  }, [licenseFilterquery]);

  const filterParams = useMemo(() => {
    const includes4g = licenseFilterquery.includes4G?.map(
      (item) => item === FOUR_G_STATUS.YES,
    );
    const types = licenseFilterquery.type?.map((item) => {
      return licenseTypeValues[
        item?.toUpperCase() as keyof typeof licenseTypeValues
      ];
    });
    const duration = licenseFilterquery.duration?.map((item) => {
      return durationTypeValues[
        item?.toUpperCase() as keyof typeof durationTypeValues
      ];
    });

    return {
      includes4g: includes4g?.join(','),
      types: types?.join(','),
      duration: duration?.join(','),
    };
  }, [licenseFilterquery]);

  const licenseReqObj = useMemo(() => {
    return {
      // In case userScope is undefined in order to avoid the error sending the restricted scope "COMPANY"
      scope: userScope || SCOPE_TYPE.COMPANY,
      limit: ROW_PER_PAGE,
      offset:
        ROW_PER_PAGE * ((licenseFilterquery?.page || NUMBER.ONE) - NUMBER.ONE),
      'filter_in[status]': licenseFilterquery.status?.join(',')?.toUpperCase(),
      'filter_in[duration]': filterParams.duration,
      'filter_in[type]': filterParams.types,
      'filter_in[includes4G]': filterParams.includes4g,
      'filter_in[chargerId]': licenseFilterquery.chargerId?.join(','),
      'filter_like[searchValue]': licenseFilterquery.searchValue?.join(','),
    };
  }, [licenseFilterquery, filterParams, ROW_PER_PAGE]);

  const getBgColor = (status: string) => {
    return status.toLowerCase() === LICENSE_STATUS.EXPIRED
      ? 'bg-warning_light'
      : '';
  };

  const { licensesData, isLicenseLoading, licensesDataCount } =
    useFetchLicenseListQuery(licenseReqObj, {
      selectFromResult: (endPoint) => ({
        licensesData:
          endPoint.data?.entities.map((item: License) => ({
            ...item,
            bg: getBgColor(item.status),
          })) || [],
        isLicenseLoading: endPoint.isLoading || endPoint.isFetching,
        licensesDataCount: endPoint.data?.totalCount || 0,
      }),
    });

  const handleSelectFilter = (type: string, value: string[]) => {
    const filterVal = value?.length ? value : undefined;
    setLicenseFilterquery({ [type]: filterVal });
  };

  const handleClearAllClick = () => {
    setLicenseFilterquery({
      searchValue: undefined,
      status: undefined,
      duration: undefined,
      includes4G: undefined,
      type: undefined,
      chargerId: undefined,
      page: 1,
    });
  };

  const handleLoadPage = (page: number) => {
    setLicenseFilterquery({ page: page });
  };

  const values = useMemo(() => {
    return {
      licensesData,
      isLicenseLoading,
      licensesDataCount,
      licenseFilterquery,
      totalSelectedFilters,
      handleSelectFilter,
      handleClearAllClick,
      handleLoadPage,
    };
  }, [
    licensesData,
    isLicenseLoading,
    licensesDataCount,
    licenseFilterquery,
    totalSelectedFilters,
    handleSelectFilter,
    handleClearAllClick,
    handleLoadPage,
  ]);

  return (
    <licenseContext.Provider value={values}>{children}</licenseContext.Provider>
  );
};
