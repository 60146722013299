import { Price } from '../stores/types/price.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE } from './utils';

export const pricingApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPricingModels: builder.query<
      Price[],
      { companyId?: string; scope: SCOPE_TYPE }
    >({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}`,
        method: 'GET',
        params: params,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
      ],
    }),
    createPricingModel: builder.mutation<
      any,
      {
        displayName: string;
        chargerIds?: string[];
        scheduleType: string;
        tieredPriceConditions: any;
        dayPrices: any;
      }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}`,
        body: payload,
        method: 'POST',
        toast: {
          successTrans: 'tiered_pricing_success_add',
          values: {
            name: payload.displayName,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.CHARGERS },
      ],
    }),
    editPricingModel: builder.mutation<
      any,
      {
        id: string;
        displayName: string;
        chargerIds?: string[];
        scheduleType: string;
        tieredPriceConditions?: any;
        dayPrices: any;
      }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}/${payload.id}`,
        body: payload,
        method: 'PUT',
        toast: {
          successTrans: 'tiered_pricing_success_edit',
          values: {
            name: payload.displayName,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.CHARGERS },
      ],
    }),
    deletePricingModel: builder.mutation<any, Price>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.CHARGERS },
      ],
    }),
    updateCurrency: builder.mutation<
      any,
      {
        currency: string;
      }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}:set-currency`,
        body: payload,
        method: 'POST',
      }),
    }),

  }),
});

export const {
  useFetchPricingModelsQuery,
  useLazyFetchPricingModelsQuery,
  useCreatePricingModelMutation,
  useEditPricingModelMutation,
  useDeletePricingModelMutation,
  useUpdateCurrencyMutation,
} = pricingApi;
