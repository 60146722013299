import { createContext, useContext, useEffect, useMemo } from 'react';
import { useFetchCompanyDetailQuery } from '../services/company.api';
import { useLazyFetchPersonalizationQuery } from '../services/featureinfo.api';
import { Company, FeatureDetails } from '../stores/types/company.interface';
import { FeatureInfo } from '../stores/types/personalization.interface';
import { useAuth } from './useAuth';
import { useCompany } from './useCompany';

type ContextType = {
  allowPowermanEdit: boolean; // Powerman Edit Allowed
  allowStateOfChargeChargersView: boolean; // State of Charge View Allowed
  allowSparkSignals: boolean;
  allowPayterView: boolean; // State of payter view
  allowNyserdaReports: boolean; // Feature to show/hide nyserda reports dropdown option
  allowSparkAnalyze: boolean;
  allowSparkSurvey: boolean;
  company: Company | undefined;
  isCompanyLoading: boolean;
  isFetchCompanySuccess: boolean;
};

const initialState: ContextType = {
  allowPowermanEdit: false,
  allowStateOfChargeChargersView: false,
  allowSparkSignals: false,
  allowPayterView: false,
  allowNyserdaReports: false,
  allowSparkAnalyze: false,
  allowSparkSurvey: false,
  company: undefined,
  isCompanyLoading: false,
  isFetchCompanySuccess: false,
};

const AllowedFeaturesContext = createContext(initialState);

export const useAllowedFeatures = () => {
  return useContext(AllowedFeaturesContext);
};

const useProvideAllowedFeatures = (): ContextType => {
  const auth = useAuth();
  const [triggerFetchPersonalization, { data: personalization }] =
    useLazyFetchPersonalizationQuery();
  const { companyId, userScope } = useCompany();
  const { company, isFetchCompanySuccess, isCompanyLoading } =
    useFetchCompanyDetailQuery(
      {
        id: auth.user?.attributes?.profile?.lastActiveCompanyId,
        scope: userScope!,
      },
      {
        selectFromResult: (endPoint) => ({
          company: endPoint.data ? endPoint.data : ({} as Company),
          isFetchCompanySuccess: endPoint.isSuccess,
          isCompanyLoading: endPoint.isLoading || endPoint.isFetching,
        }),
      },
    );

  const getFeatureFlagsMap = useMemo(() => {
    const featureFlagsMap: { [key: string]: boolean } = {};

    personalization?.features?.forEach((feature: FeatureInfo) => {
      featureFlagsMap[feature.featureName] = feature.enabled;
    });
    company?.features?.forEach((feature: FeatureDetails) => {
      featureFlagsMap[feature.featureName] = feature.enabled;
    });
    return featureFlagsMap;
  }, [personalization, companyId, company]);

  useEffect(() => {
    if (auth?.user) {
      triggerFetchPersonalization();
    }
  }, [auth, triggerFetchPersonalization]);

  return {
    allowPowermanEdit: getFeatureFlagsMap.Y2024_powerman_edit || false,
    allowStateOfChargeChargersView:
      getFeatureFlagsMap.Y2024_state_of_charge_chargers_view || false,
    allowSparkSignals: getFeatureFlagsMap.Y2024_spark_signals || false,
    allowPayterView: getFeatureFlagsMap.Y2024_payter || false,
    allowNyserdaReports: getFeatureFlagsMap.Y2024_nyserda || false,
    allowSparkAnalyze: getFeatureFlagsMap.Y2024_spark_in_dashboard || false,
    allowSparkSurvey: getFeatureFlagsMap.Y2025_spark_survey || false,
    company,
    isCompanyLoading,
    isFetchCompanySuccess,
  };
};

export const AllowedFeaturesProvider = (props: any) => {
  const { children } = props;
  const res = useProvideAllowedFeatures();

  return (
    <AllowedFeaturesContext.Provider value={res}>
      {children}
    </AllowedFeaturesContext.Provider>
  );
};
