import { useMemo } from 'react';
import Modal from 'react-modal';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
} from '..';
import { ExitDeleteRemoveClose } from '../../../assets/icons';
import { useWindowSize } from '../../../hooks';
import { useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

const WINDOW_H_PADDING = 80; // top 40 bottom 40

export const InfoModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const windowSize = useWindowSize();
  const { modalProps } = store;
  const {
    title,
    width = '540px',
    height = 'max-content',
    overflow = 'auto',
    onRenderBody,
    onRenderFooter,
    shouldCloseOnOverlayClick = true,
    onHeaderButtonOnlick = () => {},
    showHeaderButton = false,
    headerButtonType = ButtonType.DESTRUCTIVE,
    headerButtonSizie = ButtonSize.SMALL,
    headerButtonLabel = '',
    headerButtonLabelTestId = '',
    headerClass = 'w-full h-16',
    icon = ExitDeleteRemoveClose,
    onIconClick = () => hideModal(),
    bodyStyleClass = 'flex-grow mt-2',
  } = modalProps;

  const handleClose = () => {
    hideModal();
  };

  const renderHeader = () => {
    return (
      <div className='sticky top-0 z-50 bg-white'>
        <div className={`flex items-center justify-center ${headerClass}`}>
          <Icon
            src={icon}
            className='!hover:bg-grey2 rounded-full flex items-center justify-center absolute left-0 cursor-pointer'
            onClick={onIconClick}
          />
          <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
          {showHeaderButton && (
            <div className='absolute right-2'>
              <Button
                label={headerButtonLabel}
                type={headerButtonType}
                size={headerButtonSizie}
                onClick={onHeaderButtonOnlick}
                dataTestId={headerButtonLabelTestId}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={bodyStyleClass}>{onRenderBody && onRenderBody()}</div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='sticky top-0'>{onRenderFooter && onRenderFooter()}</div>
    );
  };

  const modalMaxHeight = useMemo(() => {
    if (windowSize.height === 0) {
      return 640;
    }
    return windowSize.height - WINDOW_H_PADDING;
  }, [windowSize.height]);

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        content: {
          overflow,
          top: '50%',
          left: '50%',
          width,
          height,
          maxHeight: modalMaxHeight,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className='flex flex-col px-5'>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </div>
    </Modal>
  );
};
