import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dollar, ErrorWarningtraiangle, Info } from '../../../assets/icons';
import {
  Currency,
  CURRENCY_IDLE_FEE_RANGES,
} from '../../../constant/Currency.constant';
import { IconSize } from '../../../constant/IconSize.constant';
import { IdleFeeStatus } from '../../../constant/IdleFeeStatus.constant';
import { getIdleFeesEnabled } from '../../../stores/selectors/deployment.selector';
import { Company } from '../../../stores/types';
import {
  DayPrice,
  RuleTypes,
  ScheduleType,
} from '../../../stores/types/price.interface';
import {
  CheckBoxData,
  ColorType,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Switch,
} from '../../_ui';
import { InputType, ValidationType } from '../../_ui/Input.component';
import { PricingCalendar } from '../PricingCalendar.component';
import { getRuleLabel } from '../utils';
import PriceFormStepsHead from './PriceFormStepsHead.component';
import TieredPricing from './TieredPricing/TieredPricing.component';

export interface IdleFeeDetails {
  enable: boolean;
  idleRate: string | number;
}
interface IProps {
  dayPrices: DayPrice[] | undefined;
  scheduleItems: Array<any>;
  tierPrices: Array<any>;
  priceType: string;
  setPriceType: Function;
  setTierPrices: Function;
  scheduleType: ScheduleType | RuleTypes;
  handleScheduleTypeChange: Function;
  handleCalendarChange: Function;
  disabled?: boolean;
  idleFeeDetails: IdleFeeDetails;
  setIdleFeeDetails: Function;
  companyInfo?: Company;
  idleFeeStatus: IdleFeeStatus;
}
const PriceForm = ({
  dayPrices,
  scheduleItems,
  scheduleType,
  tierPrices,
  priceType,
  setPriceType,
  setTierPrices,
  idleFeeDetails,
  setIdleFeeDetails,
  handleScheduleTypeChange,
  handleCalendarChange,
  disabled = false,
  companyInfo,
  idleFeeStatus,
}: IProps) => {
  // Idle fee feature flag
  const idleFeesEnabled: boolean = useSelector(getIdleFeesEnabled);

  const ruleItems = useMemo(() => {
    return Object.keys(RuleTypes).map((key) => ({
      id: key,
      label: getRuleLabel(key),
      selected: key === priceType,
      disabled,
    }));
  }, [priceType, disabled]);

  const handleRuleTypeChange = (items: CheckBoxData[]) => {
    items.forEach((item) => {
      if (item && item.selected && item?.id) {
        setPriceType(item.id as RuleTypes);
      }
    });
  };

  const pricingDesc = useMemo(() => {
    let desc;
    let desc2;
    if (priceType === RuleTypes.FREE) {
      desc = t('pricing-step2-desc');
    } else if (priceType === RuleTypes.TIME_OF_USE) {
      desc = t('pricin_schedule_line_1');
      desc2 = t('pricin_schedule_line_2');
    } else {
      desc = t('pricing_step2_tiered_desc');
    }
    return { desc, desc2 };
  }, [priceType]);

  const renderIdleFeeStatusMsg = () => {
    if (
      idleFeeStatus === IdleFeeStatus.EMPTY ||
      idleFeeStatus === IdleFeeStatus.APPROPRIATE ||
      !companyInfo
    ) {
      return null;
    }

    const icon =
      idleFeeStatus === IdleFeeStatus.TOO_LOW ? ErrorWarningtraiangle : Info;
    const color =
      idleFeeStatus === IdleFeeStatus.TOO_LOW
        ? ColorType.WARNING
        : ColorType.NEGATIVE;

    const currencyRanges =
      CURRENCY_IDLE_FEE_RANGES[companyInfo?.currency as Currency];
    const startAmount = currencyRanges.startAmount.toFixed(2);
    const endAmount = currencyRanges.endAmount.toFixed(2);
    const text =
      idleFeeStatus === IdleFeeStatus.TOO_LOW
        ? t('idle_fee_too_low', {
            startAmount,
            endAmount,
          })
        : idleFeeStatus === IdleFeeStatus.TOO_HIGH
        ? t('idle_fee_too_high', {
            startAmount,
            endAmount,
          })
        : t('idle_fee_exceed_limit', {
            amount: currencyRanges.maxAmount.toFixed(2),
            startAmount,
            endAmount,
          });

    return (
      <div className='flex flex-row gap-2 mt-2 p-2 bg-grey1 max-w-[538px] rounded-[8px]'>
        <Icon size={IconSize.SIZE_24x24} src={icon} color={color} />
        <div className='flex flex-col gap-2'>
          <Label
            text={text}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
            className='whitespace-pre-line'
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <PriceFormStepsHead
        title={t('pricing-step1-title')}
        desc={t('pricing-step1-desc')}
        desc2={t('sales_tax_applied_to_paid_sessoins')}
      />
      <div
        className='flex flex-row items-center h-[40px]'
        data-testid='pricingTypes'
      >
        <div className='w-[148px]'>
          <Label
            className='min-w-[148px]'
            text={t('rule_type')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
        </div>
        <RadioGroup
          direction={GroupDirection.Horizontal}
          defaultItems={ruleItems}
          onChange={handleRuleTypeChange}
        />
      </div>
      <PriceFormStepsHead
        title={t('pricing-step2-title')}
        desc={pricingDesc.desc}
        desc2={pricingDesc.desc2}
      />
      {priceType === RuleTypes.TIME_OF_USE && (
        <>
          <div
            className='flex flex-row items-center h-[40px]'
            data-testid='pricingDetails'
          >
            <div className='w-[148px]'>
              <Label
                className='min-w-[148px]'
                text={t('pricing_type')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
            <RadioGroup
              direction={GroupDirection.Horizontal}
              defaultItems={scheduleItems}
              onChange={handleScheduleTypeChange}
            />
          </div>
          <div className='mt-[16px]'>
            <PricingCalendar
              scheduleType={scheduleType}
              dayPrices={dayPrices}
              onChange={handleCalendarChange}
              disabled={disabled}
            />
          </div>
        </>
      )}
      {priceType === RuleTypes.TIERED && (
        <TieredPricing
          tierPrices={tierPrices}
          setTierPrices={setTierPrices}
          disabled={disabled}
        />
      )}
      {/* Idle fees setup configuration */}
      {idleFeesEnabled && (
        <>
          <div className='flex flex-col gap-2 mt-[32px]'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label text={t('pricing-step3-title')} type={LabelType.LABEL_M} />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
              />
            </div>
            <Label
              text={t('idle_fee_sub_title')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-row gap-x-14 mt-3'>
            <div className='flex flex-row gap-2 items-center'>
              <Label
                text={t('idle_fee_label')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
              <Switch
                value={idleFeeDetails.enable}
                onChange={(enable: boolean) => {
                  setIdleFeeDetails({
                    idleRate: enable ? idleFeeDetails.idleRate : '',
                    enable,
                  });
                }}
                hideLabel
                dataTestId='idleFeeSwitch'
              />
            </div>
            <div className='flex flex-row gap-x-3 items-center'>
              <FormInput
                inputTitle={t('idle_fee_label')}
                dataTestId='idleFeeInput'
                placeholder='0.00'
                onChange={(event: any) => {
                  if (event.target.value !== idleFeeDetails.idleRate) {
                    setIdleFeeDetails({
                      ...idleFeeDetails,
                      idleRate: event.target.value,
                    });
                  }
                }}
                defaultValue={idleFeeDetails.idleRate}
                validationType={ValidationType.IDLE_FEE}
                icon={Dollar}
                iconColor={
                  idleFeeDetails.idleRate ? ColorType.GREY6 : ColorType.GREY3
                }
                width={
                  idleFeeStatus !== IdleFeeStatus.EXCEED_LIMIT
                    ? '88px'
                    : '107px'
                }
                showOutline={idleFeeDetails.enable}
                disabled={!idleFeeDetails.enable}
                showErrorIcon={idleFeeDetails.enable}
                inputType={InputType.CURRENCY_INPUT}
                companyCurrency={companyInfo?.currency}
                maxLen='5'
              />
              <Label
                text={t('idle_fee_hints')}
                type={LabelType.BODY3}
                color={
                  idleFeeDetails.enable ? ColorType.GREY6 : ColorType.GREY3
                }
              />
            </div>
          </div>
          {renderIdleFeeStatusMsg()}
        </>
      )}
      {/* Idle fees setup configuration end */}
    </>
  );
};

export default PriceForm;
