import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ColorType, Label, LabelType } from '../_ui';
import GenerateLicenses from './GenerateLicenses.component';

interface IPROPS {
  children: React.ReactNode;
}

const LicensesWrapper = ({ children }: IPROPS) => {
  const { t } = useTranslation();

  return (
    <Card className='py-4'>
      <div className='divide-y divide-grey2'>
        <div className='p-0'>
          <div className='flex flex-row items-center justify-between pb-4'>
            <div className='flex'>
              <Label
                text={t('licenses')}
                type={LabelType.H4}
                color={ColorType.BLACK}
              />
            </div>
            <div className='flex'>
              <GenerateLicenses />
            </div>
          </div>
          {children}
        </div>
      </div>
    </Card>
  );
};

export default LicensesWrapper;
