import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ColorType, Icon, Label, LabelType, Scrollable } from '.';
import { Dashboard } from '../../assets/icons';
import { USER_ROLE, useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import {
  GroupItem,
  RoutePath,
  checkIfSupportRoute,
  routes,
  sideBarMenus,
} from '../../routes';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { getbrandname } from '../../utils/SearchParam.Utils';
import { CompanySelect } from './CompanySelect.component';
import { getIsItemSelected } from './Sidebar.util';
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip.component';

export const AppSideBar = () => {
  const auth = useAuth();
  const { companyId, companies, isMultiViewActive } = useCompany();
  const theme = useSelector(getCurrentTheme);
  const currentLocation = useLocation();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();
  const { t } = useTranslation();

  const handleMenuClick = (appMenu: any) => {
    navigateWithCompanyId(`${appMenu.path}`, companyId, { replace: false });
  };

  const noCompanyMenu = [
    {
      items: [
        {
          header: '',
          path: RoutePath.OVERVIEW,
          icon: Dashboard,
          ignoreClick: true,
        },
      ],
    },
  ];

  const renderLogo = () => {
    return (
      <img
        src={theme.networkLogo}
        width={176}
        height='auto'
        alt={t('icon_alt_brand_logo', { brandName: getbrandname() })}
        className='py-10 cursor-pointer'
        onClick={() =>
          navigateWithCompanyId(`../${RoutePath.OVERVIEW}`, companyId, {
            replace: true,
          })
        }
      />
    );
  };

  const renderHeader = (transKey: string) => (
    <div className='block pl-2 py-4 h-10 w-[200px]' key={transKey}>
      <Label
        text={t(transKey)}
        type={LabelType.LABEL_XS}
        color={ColorType.GREY6}
      />
    </div>
  );

  const getItemWithTooltip = (item: any, path: string) => {
    const title = t(routes.find((route) => route.path === path)?.name || '');
    const labeText = (
      <Trans
        i18nKey='disabled_menu_tooltip'
        values={{
          menu: title,
        }}
      />
    );
    return (
      <Tooltip
        placement='top'
        tipOffset={{
          mainAxis: 0,
          crossAxis: 0,
        }}
      >
        <TooltipTrigger>{item}</TooltipTrigger>
        <TooltipContent>
          <Label
            text={labeText}
            type={LabelType.LABEL_S}
            color={ColorType.WHITE}
            className='w-40'
          />
        </TooltipContent>
      </Tooltip>
    );
  };

  const renderItem = (item: GroupItem) => {
    const title = t(
      routes.find((route) => route.path === item.path)?.name || '',
    );
    return (
      <div
        key={item.path}
        onClick={() =>
          item.ignoreClick || item.isDisabled ? {} : handleMenuClick(item)
        }
        className={`${
          !item.isDisabled && 'hover:bg-grey1 cursor-pointer'
        } flex items-center w-[200px] h-10 rounded`}
        style={{
          backgroundColor:
            item.isSelected && !item.isDisabled ? theme.brand_0 : '',
        }}
      >
        <Icon
          src={item.icon}
          color={item.color}
          className='ml-[15px] mr-[19px] my-[11px]'
          disabled={item.isDisabled}
        />
        <Label text={title} type={LabelType.LABEL_S} color={item.color} />
      </div>
    );
  };

  const sideBarItems = useMemo(() => {
    return sideBarMenus.map((group) => {
      return {
        ...group,
        items: group.items.map((item) => {
          const isSelected = getIsItemSelected(
            currentLocation.pathname,
            item.path,
          );

          const isDisabled =
            isMultiViewActive &&
            ((auth.role === USER_ROLE.SUPPORT &&
              ![RoutePath.CHARGERS, RoutePath.REPORTS].includes(item.path)) ||
              (auth.role === USER_ROLE.NORMAL &&
                ![
                  RoutePath.OVERVIEW,
                  RoutePath.CHARGERS,
                  RoutePath.REPORTS,
                ].includes(item.path)));

          const color = isSelected
            ? ColorType.BLACK
            : isDisabled
            ? ColorType.GREY3
            : ColorType.GREY6;
          return {
            ...item,
            isSelected,
            isDisabled,
            color,
          };
        }),
      };
    });
  }, [
    currentLocation.pathname,
    isMultiViewActive,
    theme.navigationSelectedColor,
  ]);

  const renderMenu = () => {
    return sideBarItems.map((group) => {
      const divs = [];
      if (group.header) {
        divs.push(renderHeader(group.header));
      }
      group.items.forEach((item) => {
        if (item.isDisabled) {
          divs.push(getItemWithTooltip(renderItem(item), item.path));
        } else {
          if (checkIfSupportRoute(auth.role, item.role)) {
            divs.push(renderItem(item));
          }
        }
      });
      return divs;
    });
  };

  const noCompanyrenderMenu = () =>
    noCompanyMenu.map((group) => {
      const divs: any = [];

      group.items.forEach((item) => {
        divs.push(renderItem(item));
      });
      return divs;
    });

  const displayMenu = () => {
    // user has multi company access
    if (companies && companies?.length > 1) {
      return (
        <div className='divide-y divide-grey2'>
          <div className='pb-5'>
            <CompanySelect
              placement='bottom-start'
              buttonProps={{
                iconRightAlign: true,
                buttonStyle: {
                  height: '48px',
                  width: '200px',
                },
                topLeftLabel: t('company').toUpperCase(),
              }}
            />
          </div>
          <div className='pt-5 pb-5'>{renderMenu()}</div>
        </div>
      );
    }
    // user has single company access
    if (companies?.length === 1) {
      return <div className='pb-5'>{renderMenu()}</div>;
    }
    // no company
    return <div>{noCompanyrenderMenu()}</div>;
  };

  return (
    <div className='w-60 bg-white block h-[calc(100vh)] overflow-x-hidden'>
      <Scrollable>
        <div className='pl-[32px] pr-[32px]'>{renderLogo()}</div>
        <div className='pl-5 pr-5'>{displayMenu()}</div>
      </Scrollable>
    </div>
  );
};
