import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLicense } from '../../../hooks/useLicense';
import { Dropdown, DropdownType, LabelType } from '../../_ui';
import {
  DURATION_TYPES,
  FILTER_TYPES,
  FOUR_G_STATUS,
  LICENSE_STATUS,
} from '../utils';

interface IProps {
  filterType: string;
}

const FilterDropDown = ({ filterType }: IProps) => {
  const { licenseFilterquery, handleSelectFilter } = useLicense();
  const { t } = useTranslation();

  let dropdownName = '';
  let listItems;

  switch (filterType) {
    case FILTER_TYPES.DURATION:
      dropdownName = 'duration';
      listItems = DURATION_TYPES;
      break;
    case FILTER_TYPES.INCLUDES_4G:
      dropdownName = '4G';
      listItems = FOUR_G_STATUS;
      break;
    case FILTER_TYPES.STATUS:
      dropdownName = 'status';
      listItems = LICENSE_STATUS;
      break;
    default:
      dropdownName = '';
      listItems = [];
  }

  const dropDownList = useMemo(() => {
    return Object.values(listItems).map((item) => ({
      [filterType]: item,
      label: item,
      selected: licenseFilterquery[filterType]?.includes(item),
    }));
  }, [licenseFilterquery, listItems, filterType]);

  const handleSelect = (selectedItems: any) => {
    const filteredList = selectedItems.filter(
      (item: any) => item.selected === true,
    );
    handleSelectFilter(
      filterType,
      filteredList.map((item: any) => item[filterType]),
    );
  };

  return (
    <Dropdown
      placeholder={t(dropdownName)}
      placeholderLabelType={LabelType.SESSION_DROPDOWN_HEADER}
      type={DropdownType.CHECKBOX}
      headerWidth='auto'
      items={dropDownList}
      onItemClick={handleSelect}
      translationOn
      showFooter
    />
  );
};
export default FilterDropDown;
