import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLicense } from '../../../hooks/useLicense';
import { Pill } from '../../_ui';
import { FILTER_TYPES } from '../utils';

interface IProps {
  chargersFilterDropdownList: any;
  typeDropDownList: any;
}
const RenderFilterPills = ({
  chargersFilterDropdownList,
  typeDropDownList,
}: IProps) => {
  const { licenseFilterquery, handleSelectFilter } = useLicense();
  const { t } = useTranslation();

  const handlePillClick = (filterType: string, item: string) => {
    const activeFilters = licenseFilterquery?.[filterType]?.filter(
      (filterValue: any) => filterValue !== item,
    );
    handleSelectFilter(filterType, activeFilters);
  };

  const selectedChargerFilter = useMemo(() => {
    return licenseFilterquery.chargerId?.length
      ? licenseFilterquery.chargerId.map((item: string) => {
          return { type: 'CHARGER', id: item };
        })
      : [];
  }, [licenseFilterquery]);

  const selectedTypeFilter = useMemo(() => {
    return licenseFilterquery.type?.length
      ? licenseFilterquery.type.map((item: string) => {
          return { type: 'PORT', id: item };
        })
      : [];
  }, [licenseFilterquery]);

  const handleChargerPillClick = (item: any) => {
    if (item.type === 'CHARGER') {
      const filterCharger = [...selectedChargerFilter].filter(
        (i) => i.id !== item.id,
      );
      handleSelectFilter(
        FILTER_TYPES.CHARGER_ID,
        filterCharger?.map((charger) => charger.id),
      );
    } else {
      const filteredChargersList: any =
        chargersFilterDropdownList.find((ele: any) => ele.id === item.id)
          ?.children || [];
      const filteredChargersListIds: string[] = filteredChargersList.map(
        (ele: any) => ele.id,
      );
      const filterCharge = [...selectedChargerFilter].filter((i) => {
        return !filteredChargersListIds.includes(i.id || '');
      });
      handleSelectFilter(
        FILTER_TYPES.CHARGER_ID,
        filterCharge?.map((charger) => charger.id),
      );
    }
  };

  const handleTypePillClick = (item: any) => {
    if (item.type === 'PORT') {
      const filterTypes = [...selectedTypeFilter].filter(
        (i) => i.id !== item.id,
      );
      handleSelectFilter(
        FILTER_TYPES.TYPE,
        filterTypes?.map((type) => type.id),
      );
    } else {
      const filteredTypesList: any =
        typeDropDownList.find((ele: any) => ele.id === item.id)?.children || [];
      const filteredTypesListIds: string[] = filteredTypesList.map(
        (ele: any) => ele.id,
      );
      const filterType = [...selectedTypeFilter].filter((i) => {
        return !filteredTypesListIds.includes(i.id || '');
      });
      handleSelectFilter(
        FILTER_TYPES.TYPE,
        filterType?.map((type) => type.id),
      );
    }
  };

  const renderSelectedCharger = () => {
    if (!licenseFilterquery?.chargerId) {
      return <div />;
    }
    const chargerPillsToShow: any[] = [];
    chargersFilterDropdownList.forEach((ele: any) => {
      if (ele.selected) {
        chargerPillsToShow.push({
          id: ele.id,
          label: ele.label,
          type: 'LOCATION',
        });
      } else {
        ele.children.forEach((child: any) => {
          if (child.selected) {
            chargerPillsToShow.push({
              id: child.id,
              label: child.label,
              type: 'CHARGER',
            });
          }
        });
      }
    });

    return (
      <>
        {chargerPillsToShow.map((item) => {
          return (
            <Pill
              key={item.id}
              label={item.label}
              hasCloseButton
              onClick={() => handleChargerPillClick(item)}
              translationOn
            />
          );
        })}
      </>
    );
  };

  const renderSelectedTypes = () => {
    if (!licenseFilterquery?.type) {
      return <div />;
    }
    const typePillsToShow: any[] = [];
    typeDropDownList.forEach((ele: any) => {
      if (ele.selected) {
        typePillsToShow.push({
          id: ele.id,
          label: ele.label,
          type: 'TYPE',
        });
      } else {
        ele.children.forEach((child: any) => {
          if (child.selected) {
            typePillsToShow.push({
              id: child.id,
              label: child.label,
              type: 'PORT',
            });
          }
        });
      }
    });
    return (
      <>
        {typePillsToShow.map((item) => {
          return (
            <Pill
              key={item.id}
              label={item.id}
              hasCloseButton
              onClick={() => handleTypePillClick(item)}
              translationOn
            />
          );
        })}
      </>
    );
  };

  const renderPills = (filterType: string) => {
    if (filterType === FILTER_TYPES.CHARGER_ID) {
      return renderSelectedCharger();
    }
    if (filterType === FILTER_TYPES.TYPE) {
      return renderSelectedTypes();
    }
    return licenseFilterquery[filterType]?.map((item: any) => {
      let label;
      switch (filterType) {
        case FILTER_TYPES.SEARCH_VALUE:
          label = `"${item}"`;
          break;
        default:
          label = item;
      }

      return (
        <Pill
          key={item}
          label={label}
          hasCloseButton
          onClick={() => handlePillClick(filterType, item)}
          translationOn
          closeButtonAlt={t('icon_alt_remove_filter_tag')}
        />
      );
    });
  };

  return (
    <div className='flex flex-row items-center gap-2 my-1.5 flex-wrap'>
      {Object.values(FILTER_TYPES).map((filter: string) => renderPills(filter))}
    </div>
  );
};

export default RenderFilterPills;
