import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { useCharger } from '../../../hooks/useChargers';
import { useLicense } from '../../../hooks/useLicense';
import { Charger } from '../../../stores/types';
import InputSearch from '../../_ui/InputSearch/InputSearch.component';
import { FILTER_TYPES, LICENSE_PORT_TYPES, LICENSE_TYPES } from '../utils';
import ChargerDropdown from './ChargerDropdown.component';
import ClearAllButton from './ClearAllButton.component';
import FilterDropDown from './FilterDropDown.component';
import RenderFilterPills from './RenderFilterPills.component';
import TypeDropdown from './TypeDropdown.component';

const LicenseFilters = () => {
  const { t } = useTranslation();
  const { chargers } = useCharger();
  const { totalSelectedFilters, licenseFilterquery, handleSelectFilter } =
    useLicense();

  const handleSearch = (item: any) => {
    const filteredSearches =
      licenseFilterquery.searchValue?.filter(
        (search: string) => search === item,
      ) || [];

    let arr: any[] = licenseFilterquery.searchValue || [];
    if (filteredSearches.length > 0) {
      arr = [...(licenseFilterquery.searchValue || [])];
    } else {
      arr = [...(licenseFilterquery.searchValue || []), item];
    }
    handleSelectFilter(FILTER_TYPES.SEARCH_VALUE, arr);
  };

  const chargersGroupByLocation = _.groupBy(chargers, 'location.name');
  const ChargersWithLocation = Object.entries(chargersGroupByLocation)
    .map((item) => ({
      ...item,
      chargers: item[1],
    }))
    .filter((item) => item.chargers.length > NUMBER.ZERO);

  const chargersFilterDropdownList = useMemo(() => {
    return ChargersWithLocation.map((key: any) => {
      const children = key.chargers.map((charger: Charger) => {
        return {
          id: charger.id,
          label: charger.name,
          selected: licenseFilterquery.chargerId?.includes(
            charger.id as string,
          ),
        };
      });

      return {
        id: key[0],
        label: key[0],
        selected: children?.every((child: any) => child.selected) || false,
        children: children || [],
      };
    });
  }, [licenseFilterquery, ChargersWithLocation]);

  const typeDropDownList = useMemo(() => {
    return Object.values(LICENSE_TYPES).map((type) => {
      const childTypes =
        type === LICENSE_TYPES.HOME
          ? [LICENSE_TYPES.HOME]
          : Object.values(LICENSE_PORT_TYPES);
      const children = childTypes.map((port) => ({
        id: `${type}_${port}`,
        label: `${port}`,
        selected: licenseFilterquery.type?.includes(`${type}_${port}`),
      }));
      return {
        id: type,
        label: `${type}`,
        selected: children?.every((child: any) => child.selected) || false,
        children: children,
      };
    });
  }, [licenseFilterquery]);

  return (
    <>
      <div className='flex flex-row justify-between'>
        <div className='flex gap-3'>
          <InputSearch
            width='280px'
            iconSize={IconSize.SIZE_24x24}
            inputIconPadding='pl-11'
            suffixIconClassName='mr-4'
            options={[]}
            onSelect={handleSearch}
            placeholder={t('filter_by_license_or_order')}
            contentDivWidth='w-[277px]'
            disabled={false}
            inputId='license-input-search'
          />
          <FilterDropDown filterType={FILTER_TYPES.STATUS} />
          <ChargerDropdown
            chargersFilterDropdownList={chargersFilterDropdownList}
          />
          <TypeDropdown typeDropDownList={typeDropDownList} />
          <FilterDropDown filterType={FILTER_TYPES.INCLUDES_4G} />
          <FilterDropDown filterType={FILTER_TYPES.DURATION} />
        </div>
      </div>
      <div className='flex flex-row gap-2 items-baseline justify-between mt-2'>
        <RenderFilterPills
          chargersFilterDropdownList={chargersFilterDropdownList}
          typeDropDownList={typeDropDownList}
        />
        <div className='flex flex-row items-baseline min-w-fit'>
          {!!totalSelectedFilters && <ClearAllButton />}
        </div>
      </div>
    </>
  );
};

export default LicenseFilters;
