import { Session } from '../stores/types';
import { SESSION_TYPES } from '../stores/types/sessions.interface';

export const getStartedStatus = (session: Session) => {
  const { startedVia } = session;

  let status = startedVia;
  if (startedVia === 'PAYMENT_TERMINAL') {
    status = SESSION_TYPES.PAYMENT_TERMINAL;
  } else if (startedVia === 'SYSTEM') {
    status = SESSION_TYPES.DRIVER_APP;
  } else if (startedVia === 'AUTOSTART') {
    status = SESSION_TYPES.CHARGER_AUTO_START;
  } else if (startedVia === 'WEB_APP') {
    status = SESSION_TYPES.WEB_APP;
  } else if (startedVia === 'IOS_APP') {
    status = SESSION_TYPES.IOS_APP;
  } else if (startedVia === 'ANDROID_APP') {
    status = SESSION_TYPES.ANDROID_APP;
  } else if (startedVia === 'DASHBOARD') {
    status = SESSION_TYPES.DASHBOARD;
  } else if (startedVia === 'ROAM') {
    status = SESSION_TYPES.ROAM;
  }
  return status;
};
